import { extractBirthdateFromSsnSwe } from "@opr-finance/utils/src/extractBirthdateFromSsn";
import { T_Application, T_ApplicationPayload } from "../types/general";
import { addCentury } from "@opr-finance/utils/src/addCentury";
import { getTrackingData, extractSessionId } from "@opr-finance/utils";
import { isProduction } from "./getEnvironment";

export type T_TrackingData = {
    source: string;
    subsource: string;
    redirectId: string;
    gaClientId: string;
    clientApplicationId: string;
};

export const applicationMapper = (data: T_Application): T_ApplicationPayload => {
    const dataPayload: T_ApplicationPayload = {
        ...data,
        allowMarketingConsent: false,
        allowPreferencesConsent: false,
        allowStatisticsConsent: false,
        clientApplicationId: "",
        gaClientId: "",
        gaSessionId: "",
        gaTransactionId: "",
        production: isProduction,
        redirectId: "",
        source: "own_channel",
        subsource: "",
        timestamp: (Date.now() * 1000).toString(),
    };
    const parsedAmount = parseInt(data.amount.replace(/\s+/g, ""), 10);
    dataPayload.amount = Number.isNaN(parsedAmount) ? "0" : parsedAmount.toString();
    dataPayload.applicantBirthday = extractBirthdateFromSsnSwe(data.ssn);
    dataPayload.ssn = addCentury(data.ssn);
    dataPayload.applicantName = `${data.applicantGivenName} ${data.applicantSurname}`;
    dataPayload.companyAddressZip = data.companyAddressZip.replace(/\s/g, "");

    const gaMeasurementId = process.env.REACT_APP_GA_MEASUREMENT_ID ?? "";
    dataPayload.gaSessionId = extractSessionId(gaMeasurementId) ?? "";
    let trackingData: T_TrackingData = getTrackingData(
        process.env.REACT_APP_REDIRECTAPIID ?? "",
        process.env.REACT_APP_CLICKCHANNEL ?? "",
        process.env.REACT_APP_SUBSOURCE_COOKIE ?? ""
    );

    trackingData.source = trackingData.source || "own_channel";
    dataPayload.gaTransactionId = trackingData.clientApplicationId;
    return { ...dataPayload, ...trackingData };
};
