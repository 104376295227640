import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

export function getCookie(cname: string): string {
    const name = cname + "=";
    const document = (window as any).document;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (const element of ca) {
        const c = element.trimStart();
        if (c.startsWith(name)) {
            return c.slice(name.length);
        }
    }
    return "";
}

export function getGAClientId() {
    var id = "";
    if ((window as any).Cookiebot?.consent?.marketing) {
        (window as any).ga?.getAll().forEach(function (tracker) {
            if (tracker) {
                id = tracker.get("clientId");
            } else {
            }
        });
    }
    return { gaClientId: id };
}

export function getCookieConsents() {
    return {
        allowMarketingConsent: (window as any).Cookiebot?.consent?.marketing,
        allowStatisticsConsent: (window as any).Cookiebot?.consent?.statistics,
        allowPreferencesConsent: (window as any).Cookiebot?.consent?.preferences,
    };
}

export function getClientApplicationId() {
    let clientApplicationId = "";
    if (sessionStorage.getItem("clientApplicationId") === null) {
        clientApplicationId = Math.random().toString().substring(2, 5) + new Date().getTime();
        sessionStorage.clientApplicationId = clientApplicationId;
    } else {
        clientApplicationId = sessionStorage.clientApplicationId;
    }
    return clientApplicationId;
}

export function getSourceInfo(
    redirectIdCookieName: string,
    sourceCookieName: string,
    subSourceCookieName: string
) {
    return {
        clientApplicationId: getClientApplicationId(),
        subsource: getCookie(subSourceCookieName),
        source: getCookie(sourceCookieName),
        redirectId: getCookie(redirectIdCookieName),
        externalReference: getClientApplicationId(),
    };
}

export function getTrackingData(
    redirectApiIdCookieName: string,
    clickChannelCookieName: string,
    subsourceCookieName: string
) {
    const data = {
        ...getSourceInfo(redirectApiIdCookieName, clickChannelCookieName, subsourceCookieName),
        ...getCookieConsents(),
        ...getGAClientId(),
    };
    //logger.log("tracking data is: ");
    //logger.log(data);
    return data;
}

const dataLayer = (window as any).dataLayer || [];

export function sendGAEvent(eventName) {
    dataLayer.push({
        event: eventName,
    });
}

export function sendGAConversion(amount: string) {
    const amountCleaned = "" + amount;
    const amountCleanedNumber = +amountCleaned.replace(/\D/g, "") / 1;
    const clientApplicationId = getClientApplicationId();
    dataLayer.push({
        clientApplicationId: clientApplicationId,
        transactionId: clientApplicationId,
        transactionTotal: amountCleanedNumber,
    });
    sendGAEvent("sent");
}

export function sendDataLayerEvent(event) {
    dataLayer.push(event);
}

export const getUtmParameter = (parameter: string): string => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(parameter) || "";
};

export const extractSessionId = (gaMeasurementId: string): string => {
    const gaCookieName = gaMeasurementId.replace(/^G-/, "_ga_");
    const cookie = getCookie(gaCookieName);
    if (cookie) {
        const parts = cookie.split(".");
        return parts.length > 1 ? parts[2] : "";
    }
    return "";
};
