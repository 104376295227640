import { T_ApplicationPayload } from "../types/general";

export const createDataLayerEventSubmit = (data: T_ApplicationPayload) => {
    const promotionalCode =
        data.campaignCode.trim().length > 0 ? data.campaignCode : "No promotional code";
    const event = {
        event: "submit_application_business_credit",
        credit_limit: Number(data.amount),
        loan_purpose: data.reason,
        application_id: data.clientApplicationId,
        promotional_code: promotionalCode,
    };
    return event;
};

export const createDataLayerEventPurchase = (data: T_ApplicationPayload) => {
    const event = {
        event: "purchase",
        ecommerce: {
            transaction_id: data.clientApplicationId,
            value: Number(data.amount),
            currency: "SEK",
            product: "Företagskredit",
            items: [
                {
                    item_id: "BC_1",
                    item_name: "Business Credit",
                    price: Number(data.amount),
                    quantity: 1,
                },
            ],
        },
    };
    return event;
};
